import { Box, Flex, Image, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { CartFoodType, OrderType } from './receipt.type';


const SHEKEL = '₪'

const itemize = (items: string[] | undefined) => {
  if (!items) {
    return '';
  }

  return items.join(', ');
}

const api = axios.create({
  baseURL: 'https://api.mabali.net'
})

const Receipt = () => {
  let { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState<OrderType>();
  const [orderItems, setOrderItems] = useState<CartFoodType[]>([]);
  console.log('orderId', orderId);

  const getOrderDetails = useCallback(async () => {
    const { data }: { data: OrderType } = await api.get(`/orders/${orderId}/public`);
    console.log('orderDetails', data);
    setOrderDetails(data)
    setOrderItems(data.cartObj.restaurant[data.restaurant.id]);

  }, [orderId])


  useEffect(() => {
    if (orderId)
      getOrderDetails();
  }, [orderId, getOrderDetails]);


  return (
    <Box w={'full'} p={10} border="1px solid black">
      <Flex flexDirection={'column'}>
        <Flex w={'full'} justifyContent='flex-end' alignItems={'center'} >
          <Flex>
            <span style={{
              fontFamily: 'Bold'
            }}>{dayjs(orderDetails?.created_at).format('DD/MM/YY HH:mm')}</span>
          </Flex>
          <Flex flex={4} justifyContent='center'>
            <h2>קבלה עבור הזמנה</h2>
          </Flex>
          <Flex>
            <Image src='/images/appstore.png' boxSize={'50px'} />
          </Flex>

        </Flex>
        <Box>
          <Flex>
            <Table variant={'simple'} >
              <Thead>

              </Thead>
              <Tbody>
                {orderItems.map((item) => {
                  return <Tr key={item.id}>
                    <Td>{item.name_he}</Td>
                    <Td>{SHEKEL + item.price}</Td>
                    <Td>{itemize(item.Customized?.Extras.map((extra) => {
                      return extra.name_he
                    }))}</Td>
                    <Td>{itemize(item.Customized?.Options.map((option) => {
                      return `${option.menu_optional.name_he}: ${option.name_he}`
                    }))}</Td>
                    <Td>{itemize(item.Customized?.MultiOptions.map((multiOption) => {
                      return `${multiOption.name_he}: ${multiOption.qty}`
                    }))}</Td>
                  </Tr>
                })}
              </Tbody>
            </Table>
          </Flex>
          <br />
          <Flex>
            סך הזמנה: ₪{orderDetails?.total}
          </Flex>
          <br />
          <Flex>
            עלות משלוח: ₪{orderDetails?.clientDeliveryFee}
          </Flex>
          <br />
          <Flex>
            {orderDetails?.users_permissions_user.firstName + ' ' + orderDetails?.users_permissions_user.lastName}
          </Flex>
          <br />
          <Flex>
            {orderDetails?.users_permissions_user.phone}
          </Flex>
          <br />
          <Flex>
            {orderDetails?.deliveryAddressText}
          </Flex>
          <br />
          <Flex>
            {orderDetails?.id}
          </Flex>
          <br />
          <span>
            מבאלי בע"מ - בתאבון!
          </span>
        </Box>
      </Flex>
    </Box>
  )
}

export default Receipt