
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import Receipt from './Receipt';


function App() {
  return (
    <ChakraProvider>
      <Receipt />

    </ChakraProvider>
  );
}

export default App;
